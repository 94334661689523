import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  gstListData: {
    data: null,
    isLoading: false,
    isError: false,
  },
  gstDetailsData: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

// GST Listing Api
export const gstListingApi =
  ({ page, limit, startDate, endDate }) =>
  async (dispatch) => {
    try {
      dispatch(gstState([{ key: "gstListData.isLoading", value: true }]));

      const response = await axiosApi.get(
        // `admin/payment/gst-filter-history?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
        `admin/payment/gst-filter-history?page=${page}&limit=${limit}`
      );
      if (response) {
        dispatch(
          gstState([
            { key: "gstListData.isError", value: false },
            { key: "gstListData.isLoading", value: false },
            { key: "gstListData.data", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(gstState([{ key: "gstListData.isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

// GST Details Api
export const gstDetailsApi = (startDate, endDate, user) => async (dispatch) => {
  try {
    dispatch(gstState([{ key: "gstDetailsData.isLoading", value: true }]));

    const response = await axiosApi.get(
      `admin/payment/gst-history?startDate=${startDate}&endDate=${endDate}&user=${user}`
    );
    if (response) {
      dispatch(
        gstState([
          { key: "gstDetailsData.isError", value: false },
          { key: "gstDetailsData.isLoading", value: false },
          { key: "gstDetailsData.data", value: response?.data?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(gstState([{ key: "gstDetailsData.isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

const gstSlice = createSlice({
  name: "gst",
  initialState: initialStates,
  reducers: {
    gstState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { gstState } = gstSlice.actions;

const { reducer } = gstSlice;

export default reducer;
