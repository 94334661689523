import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from ".//user/slice";
import authReducer from "./auth/slice";
import dashboardReducer from "./dashboard/slice";
import layout from "./layout";
import navbar from "./navbar";
import profileReducer from "./profile/slice";
import contestReducer from "./contest/slice";
import payoutReducer from "./payout/slice";
import paymentReducer from "./payment/slice";
import tdsReducer from "./tds/slice";
import gstReducer from "./gst/slice"


const rootReducer = {
  navbar,
  layout,
  auth: authReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  profile: profileReducer,
  contest: contestReducer,
  payout: payoutReducer,
  payment: paymentReducer,
  gst:gstReducer,
  tds:tdsReducer
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export default configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});
