import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
  payoutListData: null,
  userList: null,
  payOutListingData: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

// Payout Listing Api

export const payOutListingApi =
  ({ page, limit, startDate, endDate }) =>
  async (dispatch) => {
    try {
      dispatch(
        payoutState([{ key: "payOutListingData.isLoading", value: true }])
      );
      const params = {};
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;

      const response = await axiosApi.get(
        // `admin/payment/payout/list?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
        `admin/payment/payout/list?page=${page}&limit=${limit}`
      );
      if (response) {
        dispatch(
          payoutState([
            { key: "payOutListingData.isError", value: false },
            { key: "payOutListingData.isLoading", value: false },
            { key: "payOutListingData.data", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(
        payoutState([{ key: "payOutListingData.isLoading", value: false }])
      );
      toast.error(error.response?.data?.message);
    }
  };

//** Payout List **\\
export const payoutList = (data) => async (dispatch) => {
  try {
    dispatch(payoutState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.get(`admin/payment/payout-history`, {
      params: {
        page: data?.page,
        limit: data?.limit,
        user: data?.userId,
        startDate: data?.startDate,
        endDate: data?.endDate,
      },
    });
    if (response) {
      dispatch(
        payoutState([
          { key: "isError", value: false },
          { key: "isLoading", value: false },
          { key: "payoutListData", value: response?.data?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(payoutState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

const payoutSlice = createSlice({
  name: "payout",
  initialState: initialStates,
  reducers: {
    payoutState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { payoutState } = payoutSlice.actions;

const { reducer } = payoutSlice;

export default reducer;
